import * as FullStory from '@fullstory/browser';

document.addEventListener("DOMContentLoaded", () => {
    if (document.querySelector('body')) {
        const data = document.querySelector('body').dataset || null;

        if (data.fullstoryId && data.userId) {
            FullStory.init({orgId: data.fullstoryId});

            FullStory.identify(data.userId, {
                displayName: data.userName,
                email: data.userEmail
            });
        }
    }
});




